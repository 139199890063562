<template>
  <div>
    <v-form ref="checkListForm">
      <v-container fluid class="pa-1">
        <v-progress-linear color="primary accent-4" indeterminate rounded height="6" v-if="apiLoading"></v-progress-linear><!-- fieldsList.length < 1 -->
        <form-render ref="fieldsFormRef" v-else :aliasFieldsList="aliasFieldsList" :fields="fieldsData" :checklistId="checklistId" :listOfDocuments="listOfDocuments" :dataGridImportValue="dataGridImportValue" />
        <v-divider class="mt-3 mb-1" v-if="!fromPanel"></v-divider>
        <v-row v-if="!fromPanel" class="position_fixed">
          <v-col class="text-center">
            <v-btn color="success" :loading="loading" @click="saveUpdateChecklistHandler(true)" class="ma-1">{{ $t(`message.common.${checklistId ? 'update' : 'save'}`) }}</v-btn>
            <v-btn color="info" :loading="loading" @click="saveUpdateChecklistHandler(true, true)" class="ma-1">{{ $t(`message.common.${checklistId ? 'updateAndClose' :'saveAndClose'}`) }}</v-btn>
            <v-btn color="primary" @click="closeHandler" class="ma-1">{{ $t('message.common.backToList') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import { componentStatusList } from '../../utils/support_list'
// eslint-disable-next-line camelcase
import { checklistApi, documentUrl, hostAppApi } from '@/plugins/axios_settings'
import dataGridComponent from '@/mixins/data_grid_component'
import surveyAndAdvancedSurvey from '@/mixins/survey_and_advanced_survey'
import lodash from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  mixins: [dataGridComponent, surveyAndAdvancedSurvey],
  props: {
    checklistId: {
      type: String,
      default: () => ''
    },
    formId: {
      type: String,
      default: () => ''
    },
    token: {
      type: String,
      default: () => ''
    },
    panelFields: {
      type: Array,
      default: () => []
    },
    isPanelFieldsRender: {
      type: Boolean,
      default: () => false
    },
    panelForm: {
      type: String,
      default: () => ''
    },
    fromPanel: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      default: ''
    },
    projectChecklistFormData: {
      type: Object,
      default: null
    },
    tenantUsers: {
      type: Array,
      default: () => []
    },
    parentFormRef: {
      type: Object,
      default: null
    },
    dataGridImportValue: {
      type: Object
    }
  },
  data () {
    return {
      reRender: 0,
      autoCompleteRender: 0,
      deleteDocumentId: 0,
      deleteDocumentName: '',
      statusList: componentStatusList,
      updateFileDialog: false,
      updateDocument: {},
      updateFilePicker: [],
      updateDocumentProp: '',
      fileLoader: false,
      aliasFieldsList: [],
      fieldsData: {},
      listOfFields: [],
      listOfDocuments: {},
      loading: false,
      apiLoading: false,
      checklistSetData: {
        isProject: this.module === 'project' || Boolean(parseInt(this.$route.params.project_id || 0)),
        project: this.projectChecklistFormData
      },
      // escapedCloseBracket: this.escapeRegExp(')')
      escapedCloseBracket: this.escapeRegExp(')'),
      isIos: false,
      radioLastCheckHistory: {}
    }
  },
  computed: {
    ...mapGetters(['userDetails', 'getHostRefApi', 'userId']),
    fieldsList: {
      get () {
        return this.aliasFieldsList
      },
      set (val) {
        this.aliasFieldsList = val
      }
    },
    fields: {
      get () {
        return this.fieldsData
      },
      set (val) {
        this.fieldsData = val
      }
    }
  },
  components: {
    // 'form-render': () => import('./ChecklistForm.vue')
    'form-render': () => import('../checklist/FormRender.vue')
  },
  created () {
    window.addEventListener('resize', this.checkDeviceName)
  },
  destroyed () {
    window.addEventListener('resize', this.checkDeviceName)
  },
  mounted () {
    if (this.checklistId == null) this.saveUpdateChecklistHandler()
    if (this.checklistSetData.project && Object.prototype.hasOwnProperty.call(this.checklistSetData.project, 'datagrid')) delete this.projectChecklistFormData.datagrid
    if (!this.panelForm) {
      this.getFormProperties()
    }
    this.$root.$on('callRerender', () => {
      this.reRender++
    })
    // if (!this.checklistId) this.saveUpdateChecklistHandler(false)
    this.getProjects = this.$formatter.debounceWithPromiseReturn(this.debouncedGetProjects, 200)
    const userAgent = window.navigator.userAgent.toLowerCase() || navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || navigator.platform.toLowerCase()
    this.isIos = (/iphone|ipad|ipod/.test(userAgent) || (/macintosh/.test(userAgent) && 'ontouchend' in document))
  },
  methods: {
    checkDeviceName (e) {
      const userAgent = window.navigator.userAgent.toLowerCase() || navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || navigator.platform.toLowerCase()
      this.isIos = (/iphone|ipad|ipod/.test(userAgent) || (/macintosh/.test(userAgent) && 'ontouchend' in document))
    },
    escapeRegExp (str) {
      // eslint-disable-next-line
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    },
    initWithValues () {
      this.manipulateFields()
      this.manipulateFieldsData()
    },
    getFormProperties () {
      this.apiLoading = true
      if (this.isPanelFieldsRender) {
        this.listOfFields = this.$formatter.cloneVariable(this.panelFields)
        this.initWithValues()
      } else {
        checklistApi.get(`/formproperties/get_by_form/${this.formId}`, this.token)
          .then((result) => {
            if (result && result.data) {
              this.listOfFields = result.data
              if (this.checklistId) {
                this.getFieldsData()
                this.getDocumentsHandler()
              } else this.initWithValues()
            }
          })
      }
    },
    /* TO MODIFY AND UPDATE LIST OF FIELDS */
    manipulateFields () {
      this.aliasFieldsList = this.listOfFields
      const getFields = this.$formatter.cloneVariable(this.aliasFieldsList)
      const list = getFields
      for (let i = 0; i < list.length; i++) {
        list[i].default_value = list[i].default_value ? JSON.parse(list[i].default_value) : null
        if (list[i].type === 3 || list[i].type === 4 || list[i].type === 5) {
          if (list[i].default_value.is_multiselect) {
            this.fields[list[i].name] = []
            if (list[i].default_value.default_value) this.fields[list[i].name].push(list[i].default_value.default_value)
          } else if (list[i].type !== 3) this.fields[list[i].name] = list[i].default_value.default_value
        } else if (list[i].type === 9) {
          this.listOfDocuments[list[i].name] = []
          this.fields[list[i].name] = { attachments: [] }
        } else if (list[i].type === 10) {
          this.listOfDocuments[list[i].name] = []
          list[i].expandsurveysection = false
          list[i].hideCommentsConst = list[i].default_value.hideComments
          list[i].hideAttachmentsConst = list[i].default_value.hideAttachments
          // set default values
          const surveyDefaultValuesS = list[i].default_value.surveyDefaultValues
          const selectionTypeS = (list[i].default_value.surveyOptions && list[i].default_value.surveyOptions.selectionType === 1) ? 1 : 0
          const defaultValueObjectsS = { comments: '', answer: selectionTypeS ? [] : '' }
          if (surveyDefaultValuesS && !this.$formatter.isEmptyObject(surveyDefaultValuesS)) {
            const { answer, comments } = surveyDefaultValuesS
            if (comments) defaultValueObjectsS.comments = comments
            if (answer) defaultValueObjectsS.answer = answer
          }
          this.$set(this.fields, list[i].name, { ...{ attachments: [], showComment: false, showAttachment: false }, ...defaultValueObjectsS })
          // this.$set(this.fields, list[i].name, { answer: '', comments: '', attachments: [], showComment: false, showAttachment: false })
        } else if (list[i].type === 19) {
          this.listOfDocuments[list[i].name] = []
          list[i].expandsurveysection = false
          list[i].hideCommentsConst = list[i].default_value.hideComments
          list[i].hideAttachmentsConst = list[i].default_value.hideAttachments
          // set default values
          const surveyDefaultValues = list[i].default_value.surveyDefaultValues
          const selectionType = (list[i].default_value.surveyOptions && list[i].default_value.surveyOptions.selectionType === 1) ? 1 : 0
          const defaultValueObjects = { date: '', signature: '', comments: '', answer: selectionType ? [] : '' }
          if (surveyDefaultValues && !this.$formatter.isEmptyObject(surveyDefaultValues)) {
            const { dateDefaultValueKeyword, signature, answer, comments } = surveyDefaultValues
            if (dateDefaultValueKeyword && dateDefaultValueKeyword === 'CURDATE') defaultValueObjects.date = moment().format('DD.MM.YYYY')
            if (signature) defaultValueObjects.signature = signature
            if (comments) defaultValueObjects.comments = comments
            if (answer) defaultValueObjects.answer = answer
          }
          this.$set(this.fields, list[i].name, { ...{ date_menu: false, date_value: '', attachments: [], showComment: false, showAttachment: false }, ...defaultValueObjects })
          // this.$set(this.fields, list[i].name, { answer: '', signature: '', date: '', notes: '', date_menu: false, date_value: '', comments: '', attachments: [], showComment: false, showAttachment: false })
        } else this.fields[list[i].name] = list[i].default_value ? list[i].default_value.default_value : null

        if (list[i].type === 21) {
          this.$set(this.fields, list[i].name, 'Nei')
          this.$watch(`fields.${list[i].name}`, function (val) {
            if (['Nei', 'nei'].includes(val)) return
            if (val && this.canChangeRadioStatus) {
              setTimeout(() => {
                const checkPointItems = this.listOfFields.filter(x => x.type === 20).map(x => x.name)
                let isOk = true
                if (checkPointItems && checkPointItems.length > 0) {
                  checkPointItems.forEach(propName => {
                    const itemsToCheck = this.$formatter.cloneVariable(this.fields[propName])
                    Object.keys(itemsToCheck).forEach(key => {
                      if (this.getNotAllowRadioValues().includes(itemsToCheck[key].radioBoxValue) || !itemsToCheck[key].radioBoxValue) {
                        isOk = false
                        return false
                      }
                    })
                  })
                }
                if (!isOk) {
                  this.canChangeRadioStatus = false
                  this.$awn.alert('Not all the checkpoints radio values are Satisfied')
                  this.fields[list[i].name] = 'Nei'
                }
              }, 50)
            }
          })
        }
        if (list[i].type === 20) {
          const slNo = list[i].default_value.slNo
          list[i].slNo = slNo
          var subItems = list[i].default_value.subitems ? list[i].default_value.subitems : null
          if (subItems && subItems.length > 0) {
            this.$set(this.fields, list[i].name, {})
            subItems.forEach((element, index) => {
              var obj = { radioBoxValue: '', comments: '', files: [], checkBoxU: false, date: '', filesIds: [] }
              if (this.formulaType === 3) obj.textFieldValue = ''
              this.$set(this.fields[list[i].name], element.value, obj)
              list[i][element.value] = { date_menu: false, date_value: '' }
              /* add watch prop here */
              this.$watch(`fields.${list[i].name}.${element.value}.radioBoxValue`, function (val) {
                if (val && this.getNotAllowRadioValues().includes(val)) {
                  const radioStatusItems = this.listOfFields.filter(x => x.type === 21).map(x => x.name)
                  radioStatusItems.forEach(propName => {
                    this.fields[propName] = 'Nei'
                  })
                }
              })
            })
          }
        }
        if (list[i].type === 18) {
          const defaultValue = list[i].default_value
          const labels = defaultValue.grid_data && defaultValue.grid_data.labels ? defaultValue.grid_data.labels : null
          if (labels) list[i].showSum = this.checkAndGetSumRowData(labels)
          /* if (defaultValue.grid_data && Object.keys(defaultValue.grid_data.data).length === 0) {
            list[i].default_value.grid_data.data = this.addGridFirstRow(+list[i].label, defaultValue.grid_data.data)
          } */
        }
        /* Date & Time default value set */
        if ([6, 7].includes(list[i].type)) {
          if (list[i].default_value) {
            this.fields[list[i].name] = list[i].default_value.default_value
            const defaultValueKeyword = list[i].default_value.default_Value_keyword
            if (defaultValueKeyword) {
              switch (defaultValueKeyword) {
                case 'CURDATE':
                  this.fields[list[i].name] = moment().format('DD.MM.YYYY')
                  break
                case 'CURTIME':
                  this.fields[list[i].name] = moment().format('HH:mm')
                  break
              }
            }
          }
        }
      }
      this.aliasFieldsList = list
      /* for common fields conditional and logical */
      list.forEach((element, index) => {
        element.isShow = true
        // Simple Conditional
        if (element.fielddisplayconditionwhen) {
          const foundElementIndex = this.aliasFieldsList.findIndex(x => x.name === element.fielddisplayconditionwhen)
          if (foundElementIndex !== -1) {
            this.aliasFieldsList[foundElementIndex].hideField = element.name
            this.aliasFieldsList[foundElementIndex].hideConditions = {
              condition: element.fielddisplaycondition,
              value: element.fielddisplayconditionvalue
            }
          }
        }
        // Conditional Script manipulation
        if (element.conditionscript) {
          let conditionScript = this.$formatter.$_decodeStringTobase64(element.conditionscript)
          /* run condititon script for default value set */
          /* eslint-disable no-new-func */
          const loadScript = new Function('data', 'instance', conditionScript)
          setTimeout(() => {
            loadScript(this.fields, this)
          }, 1000)
          if (this.hasConditionScript(conditionScript)) {
            conditionScript = `var show=true; \n${conditionScript} \n return show`
            /* eslint-disable no-new-func */
            element.isShowElement = new Function('data', 'instance', conditionScript)
          }
        }
        // Logical manipulation
        if (element.logicscripts && element.logicscripts.length > 0) {
          element.logicscripts.forEach(logic => {
            const logicScript = this.$formatter.$_decodeStringTobase64(logic.logicscript)
            if (element.type === 5 && logic.logicevent === 'change') {
              element[`logic_${logic.logicname}`] = logicScript
            } else {
              element[`logic_${logic.logicname}`] = logicScript
              // eslint-disable-next-line
              setTimeout(() => {
                const elementItem = this.$el.querySelector(`#_${element.name}`)
                if (elementItem) {
                  elementItem.addEventListener(logic.logicevent, (event) => {
                    this.eventHandler(event, index, logic)
                  })
                }
              }, 1000)
            }
          })
        }

        /* survey and advanced survey logical section */

        if ([10, 19].includes(element.type)) {
          const scripts = element.default_value.surveyLogicScripts
          if (scripts) this.surveyLogicScriptEncodeOrDecode(0, scripts, true, index) // set isFromRender = true
        }
        const i = index
        // separete panel fields and other fields
        const getPanelFieldIndex = list.findIndex(x => x._id === list[i].default_value.panelId)
        if (getPanelFieldIndex >= 0) {
          list[i].isPanelField = true
          if (!list[getPanelFieldIndex].panel_fields) list[getPanelFieldIndex].panel_fields = []
          list[getPanelFieldIndex].panel_fields.push(Object.assign({ ...list[i] }, { isPanelField: false }))
        }
      })
      this.aliasFieldsList = list
    },
    /* TO MODIFY AND UPDATE LIST OF FIELD\'S DATA */
    manipulateFieldsData () {
      const fields = this.fieldsData
      const data = fields.data ? JSON.parse(fields.data) : null
      this.fields = Object.assign({ ...this.fields }, { ...data })
      /* if project and has project data set the data to values  */
      if (!this.checklistId && this.checklistSetData.project) { // this.checklistSetData.isProject &&
        this.fields = { ...this.fields, ...this.checklistSetData.project }
      }
      /* --- */
      // To be called when it uses live API records
      if (data && data.values && data.values.length > 0) {
        this.aliasFieldsList.forEach((element, index) => {
          data.values.forEach(values => {
            if (element.name === values.name) {
              element.default_value.options = []
              element.default_value.options = values.options
              this.$set(this.aliasFieldsList, index, { ...element })
            }
          })
        })
      }
      // To load all the mapped data to grid list
      this.aliasFieldsList.forEach((element, index) => {
        if (element.type === 4 && this.fields[element.name]) {
          this.radioLastCheckHistory[element.name] = this.fields[element.name]
        }
        if (element.type === 10 || element.type === 19) {
          this.hasCommentOrAttachment(this.fields[element.name], element)
          if (element.type === 19) {
            const defaultValueKeyword = element.default_value.dateDefaultValueKeyword
            if (defaultValueKeyword && defaultValueKeyword === 'CURDATE') this.fields[element.name].date = moment().format('DD.MM.YYYY')
          }
          /* element.default_value.hideComments = !this.hasComments(this.fields[element.name])
          element.default_value.hideAttachments = !this.hasAttachments(this.fields[element.name]) */
          this.surveyCheckAndSetHideFields(element, this.fields[element.name].answer, index)
        }
        if (element.type === 12) {
          element.default_value.grid_data.data = (data && data[element.name]) ? data[element.name] : {}
          this.$set(this.aliasFieldsList, index, { ...element })
        }
        if (element.type === 13) {
          const reference = `signaturePad_${element._id}`
          setTimeout(() => {
            // if (data && data[element.name]) this.$refs[reference][0].fromDataURL(data[element.name])
            if (data && data[element.name]) this.$refs.fieldsFormRef.$refs[reference][0].fromDataURL(data[element.name])
            // this.$refs[reference][0].lockSignaturePad()
          }, 100)
        }
        if (element.type === 18) {
          element.default_value.grid_data.data = (data && data[element.name]) ? data[element.name] : {}
          if (this.dataGridImportValue) element.default_value.grid_data.data = { ...element.default_value.grid_data.data, ...this.dataGridImportValue.data.Datagrid }
          const gridData = element.default_value.grid_data
          Object.keys(gridData.data).forEach((key) => {
            gridData.labels.forEach((label, index) => {
              if (!gridData.data[key][index]) {
                gridData.data[key][index] = {
                  value: '',
                  isShow: true
                }
              }
            })
          })
          element.default_value.grid_data = gridData
          this.$set(this.aliasFieldsList, index, { ...element })
          setTimeout(() => {
            const gridData = element.default_value.grid_data.data
            const length = !this.$formatter.isEmptyObject(gridData) ? Object.keys(gridData).length : 0
            if (length) {
              for (let i = 0; i < length; i++) {
                this.checkAndAddConditionScripts(`row_${i}`, gridData)
              }
            }
          }, 500)
          /* Loopthrough and add condition for existing data - need after adding import csv */
          /* -- */

          // this.$set(element.default_value.grid_data, 'data', (data && data[element.name]) ? data[element.name] : {})
          /* if (element.default_value.grid_data && Object.keys(element.default_value.grid_data.data).length === 0) {
            element.default_value.grid_data.data = this.addGridFirstRow(+element.label, element.default_value.grid_data.data)
          } else if (element.default_value.grid_data && Object.keys(element.default_value.grid_data.data).length > 0) {
            // if already has data and td length differs from value then add extra empty value object
            let gridData = element.default_value.grid_data.data
            let length = !this.$formatter.isEmptyObject(gridData) ? Object.keys(gridData).length : 0
            if (length) {
              const lastRowString = `row_${length - 1}`
              let rowCols = element.default_value.grid_data.data[lastRowString]
              let labels = element.default_value.grid_data.labels
              // compare rowcols length with header lables length
              if (labels && labels.length > rowCols.length) {
                let emptyValueArray = [{ value: '' }]
                Object.keys(gridData).forEach((key) => {
                  gridData[key] = this.$formatter.cloneVariable([...gridData[key], ...emptyValueArray])
                })
              } else if (labels && labels.length < rowCols.length) {
                // if less then data remove not implemented for now
              }
            }
          } */
          this.$set(this.aliasFieldsList, index, { ...element })
        }
      })

      var checkpointComponentFields = this.aliasFieldsList.filter(x => x.type === 20)
      const newsubItemValues = {}
      checkpointComponentFields.forEach(item => {
        newsubItemValues[item.name] = {}
        const subItemsKeys = item.default_value.subitems.map(x => x.value)
        subItemsKeys.forEach(subItemKey => {
          const valueObj = this.fields[item.name][subItemKey]
          if (valueObj) {
            newsubItemValues[item.name][subItemKey] = valueObj
          } else {
            var newRecord = { radioBoxValue: '', comments: '', files: [], checkBoxU: false, date: '', filesIds: [] }
            if (this.formulaType === 3) newRecord.textFieldValue = ''
            newsubItemValues[item.name][subItemKey] = newRecord
          }
        })
      })
      for (const property in this.fields) {
        if (newsubItemValues[property]) this.fields[property] = newsubItemValues[property]
      }
      // this.fields = Object.assign({}, { ...newsubItemValues })
      /* set checkbox and date to disabled when checkbox is checked and isFinalized is true */
      checkpointComponentFields.forEach(element => {
        Object.keys(this.fields[element.name]).forEach(item => {
          this.fields[element.name][item].isDisableCheckbox = this.fields[element.name][item].checkBoxU && this.isFinished
        })
      })
      // once all done then fields loading will be false
      this.apiLoading = false
    },
    getValidation (validate, type = null) {
      switch (validate) {
        case 'required':
          if (type) return this.$_multiSelectValidation
          else return this.$_requiredValidation
        default:
          return null
      }
    },
    parseDate (date) {
      return this.$formatter.formatDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY')
    },
    getValuesFromAPI (searchText, value, index) {
      let options = []
      this.$execute.execute('get', value.apiUrl, { headers: '' }).then(response => {
        if (value.objectName) options = response.data[value.objectName]
        else options = response.data
        const fieldItem = this.aliasFieldsList[index]
        fieldItem.default_value.options = []
        fieldItem.default_value.options = options
        this.$set(this.aliasFieldsList, index, { ...fieldItem })
      })
    },
    uploadDocumentHandler (propId, propName, files) {
      this.fields[propName].attachments = null
      var formData = new FormData()
      if (files != null && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append(files[i].name, files[i])
        }
        checklistApi.post(`documents/upload?checklist_id=${this.checklistId}&property_id=${propId}`, formData, this.token)
          .then((response) => {
            if (response.data) {
              this.listOfDocuments[propName].push(...response.data)
              if (this.listOfDocuments[propName] && this.listOfDocuments[propName].length > 0) {
                this.listOfDocuments[propName].forEach(element => {
                  element.imgObj = this.mimeTypeOfAttachment(element)
                })
              }
              this.reRender++
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.updatedSuccess' })
            }
          })
      }
    },
    updateDocumentHandler () {
      if (this.updateFilePicker) {
        const formData = new FormData()
        formData.append(this.updateFilePicker.name, this.updateFilePicker)
        this.fileLoader = true
        checklistApi.post(`documents/update_attachment/${this.updateDocument._id}`, formData, this.token)
          .then((response) => {
            if (response.data) {
              const documents = this.$formatter.cloneVariable(this.listOfDocuments[this.updateDocumentProp])
              if (this.$formatter.isArrayHasData(documents)) {
                const index = documents.findIndex(x => x._id === this.updateDocument._id)
                documents.splice(index, 1, response.data[0])
                this.listOfDocuments[this.updateDocumentProp] = documents
                if (this.listOfDocuments[this.updateDocumentProp] && this.listOfDocuments[this.updateDocumentProp].length > 0) {
                  this.listOfDocuments[this.updateDocumentProp].forEach(element => {
                    element.imgObj = this.mimeTypeOfAttachment(element)
                  })
                }
                this.reRender++
              }
            }
          })
          .finally(() => {
            this.$refs.updateAttachment.reset()
            this.fileLoader = false
            this.updateFileDialog = false
          })
      }
    },
    getFieldsData () {
      checklistApi.get(`/checklists/${this.checklistId}`, this.token)
        .then((result) => {
          if (result && result.data) {
            this.fieldsData = result.data
          }
        })
        .finally(() => {
          this.initWithValues()
        })
    },
    getDocumentsHandler (propName = null) {
      checklistApi.get(`/documents/get_checklist_documents/${this.checklistId}`, this.token)
        .then((response) => {
          if (response && response.data) {
            const result = response.data
            if (result.length > 0) {
              result.forEach(element => {
                element.imgObj = this.mimeTypeOfAttachment(element)
              })
            }
            setTimeout(() => {
              const getDocuments = result ? this.$formatter.groupBy(result, 'property_name') : {}
              this.listOfDocuments = Object.assign({ ...this.listOfDocuments }, { ...getDocuments })
              if (propName) this.listOfDocuments[propName] = getDocuments[propName] || []
              this.reRender = this.reRender + 1
            }, 200)
          }
        })
    },
    clearThisSignature (id) {
      const self = this
      const reference = `signaturePad_${id}`
      setTimeout(() => {
        self.$refs[reference][0].clearSignature()
      }, 100)
    },
    saveUpdateChecklistHandler (checkValidation, canClose) {
      var parentFormValid = this.parentFormRef ? this.parentFormRef.validate() : true
      const condition = checkValidation ? this.$refs.checkListForm.validate() && parentFormValid : true
      if (condition) {
        this.loading = true
        const model = { title: '', data: [], form_id: this.formId, _id: this.checklistId }
        this.fields.values = []
        this.aliasFieldsList.forEach((element, index) => {
          if (element.type === 5 && element.default_value && element.default_value.isAPI) {
            if (this.fields[element.name]) {
              const fieldsValue = this.fields[element.name]
              const key = element.default_value.name
              this.fields.values.push({ name: element.name, options: [{ [key]: fieldsValue }] })
            }
          } else if (element.type === 12) {
            this.fields[element.name] = element.default_value.grid_data.data
          } else if (element.type === 18) {
            this.fields[element.name] = element.default_value.grid_data.data
          } else if (element.type === 1 && (element.default_value && element.default_value.use_as_title)) {
            model.title = this.fields[element.name]
          } else if (element.type === 13) {
            const reference = `signaturePad_${element._id}`
            // if (this.$refs[reference]) {
            //   const { isEmpty, data } = this.$refs[reference][0].saveSignature()
            if (this.$refs.fieldsFormRef.$refs[reference]) {
              const { isEmpty, data } = this.$refs.fieldsFormRef.$refs[reference][0].saveSignature()
              if (isEmpty) this.fields[element.name] = ''
              else this.fields[element.name] = data
            } else {
              this.fields[element.name] = ''
            }
          } else if (element.type === 16) {
            this.fields[element.name] = element.default_value
          }
          // else if (element.type === 14) {
          //  let ref = `${index}_${element.name}`
          // }
        })
        // this.fields = { ...this.dataGridImportValue, ...this.fields }
        model.data = JSON.stringify(this.fields)
        const method = model._id ? 'put' : 'post'
        const url = model._id ? `checklists/${model._id}` : 'checklists'
        checklistApi[method](url, model, this.token)
          .then(response => {
            this.$emit('update', { data: response.data, status: 'success', action: model._id ? 'update' : 'add' })
            if (canClose) this.$eventBus.$emit('closeChecklistDialog')
          }).catch((error) => {
            this.$emit('update', { data: error, status: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
      // } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
      } else {
        if (this.checklistId) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
        }
      }
      this.$root.$emit('dialog', true)
    },
    downloadDocument (id) {
      checklistApi.get(`documents/download/${id}`, this.token).then(response => {
        const filename = response.data
        window.open(`${checklistApi.defaults.baseURL}files/download?fileName=${filename}`, '_blank')
      })
    },
    editDocument (document, propName) {
      this.updateFileDialog = true
      this.updateDocument = document
      this.updateDocumentProp = propName
    },
    deleteDocument (id, propName, index) {
      checklistApi.delete(`/documents/${id}`, this.token)
        .then(response => {
          this.getDocumentsHandler(propName)
        })
    },
    closeHandler () {
      this.$emit('back')
    },
    addnewLineOnGrid (index, size) {
      const addLength = Object.keys(this.aliasFieldsList[index].default_value.grid_data.data).length
      const getGrid = this.aliasFieldsList[index].default_value.grid_data.data
      const labels = this.aliasFieldsList[index].default_value.grid_data.labels
      const gridData = []
      for (let i = 0; i < size; i++) {
        let value = ''
        if (labels[i].field.type === this.fieldTypeConstants.DATE) {
          const defaultValueKeyword = lodash.get(labels[i], 'field.typedata.dateDefaultValueKeyword')
          if (defaultValueKeyword && defaultValueKeyword === 'CURDATE') value = moment().format('DD.MM.YYYY')
        } else {
          let defauleValue = lodash.get(labels[i], 'field.typedata.default_value')
          if (labels[i].field.type === this.fieldTypeConstants.CHECKBOX) defauleValue = defauleValue ? [defauleValue] : []
          if (defauleValue) value = defauleValue
        }
        gridData.push({ value })
      }
      this.checkAndSetEventHandlers(`row_${addLength}`)
      const resultGrid = Object.assign({ ...getGrid }, { [`row_${addLength}`]: [...gridData] })
      this.aliasFieldsList[index].default_value.grid_data.data = resultGrid
      this.checkAndAddConditionScripts(`row_${addLength}`, this.aliasFieldsList[index].default_value.grid_data.data)
    },
    // eslint-disable-next-line camelcase
    mimeTypeOfAttachment ({ file_original_name, file_guid }) {
      let image = ''
      const ext = file_original_name.split('.').pop()
      const extension = ext ? ext.toString().toLowerCase() : ''
      switch (extension) {
        case 'png':
        case 'jpeg':
        case 'jpg':
        case 'gif':
          // eslint-disable-next-line camelcase
          image = { icon: 'mdi-file-image', color: null, url: `${documentUrl}/Documents/${file_guid}?t=${new Date().getTime()}` }
          break
        default:
          image = { icon: 'mdi-file-document', color: null, url: null }
          break
      }
      return image
    },
    /* Copied from weldit web  */
    /* Function for the checkpoint component */
    addFilesHandler (propId, propName, subPropName, files) {
      var formData = new FormData()
      if (files != null && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append(files[i].name, files[i])
        }
        checklistApi.post(`documents/upload?checklist_id=${this.checklistId}&property_id=${propId}`, formData)
          .then((response) => {
            if (response.data) {
              this.listOfDocuments[propName].push(...response.data)
              const ids = response.data.map(x => x._id)
              this.fields[propName][subPropName].filesIds.push(...ids)
              this.saveUpdateChecklistHandler(true, false)
              this.reRender++
            }
          })
      }
      this.fields[propName][subPropName].files = null
    },
    getSubItemFiles (propName, subPropName) {
      const fileIds = this.fields[propName][subPropName].filesIds
      const propFiles = this.$formatter.cloneVariable(this.listOfDocuments[propName])
      return propFiles ? propFiles.filter(item => fileIds.includes(item._id)) : []
    },
    setDateFieldValue (propName, subPropModel) {
      if (this.fields[propName][subPropModel].checkBoxU) {
        this.fields[propName][subPropModel].date = moment().format('DD.MM.YYYY')
        this.fields[propName][subPropModel].date_value = moment().format('YYYY-MM-DD')
        // if (!this.fields[propName][subPropModel].date) {}
      } else {
        this.fields[propName][subPropModel].date = ''
        this.fields[propName][subPropModel].date_value = ''
      }
    },
    checkAndGetSumRowData (labels) {
      const obj = { showSumRow: false, sumColIndexes: [] }
      labels.forEach((label, index) => {
        const { field } = label
        if (field && field.typedata && field.typedata.isshowsum) {
          obj.showSumRow = true
          obj.sumColIndexes.push(index)
        }
      })
      return obj
    },
    addGridFirstRow (size, dataRowValues) {
      const getGrid = dataRowValues
      const gridData = []
      for (let i = 0; i < size; i++) {
        gridData.push({ value: '' })
      }
      return Object.assign({ ...getGrid }, { [`row_${0}`]: [...gridData] })
    },
    getGridDataValuesTotal (sumIndex, data) {
      let total = 0
      const rows = Object.keys(data)
      const allRowValues = []
      rows.forEach(key => {
        var obj = data[key].find((x, y) => y === sumIndex)
        if (obj) allRowValues.push(parseFloat(obj.value || 0))
      })
      total = this.$formatter.getSum(allRowValues)
      return total
    },
    hasCommentOrAttachment (surveyObj, item) {
      let hasData = this.hasComments(surveyObj) || this.hasAttachments(item)
      hasData = Boolean(hasData)
      if (hasData) item.expandsurveysection = true
      return hasData
    },
    hasComments (surveyObj) {
      return surveyObj && surveyObj.comments
    },
    hasAttachments (item) {
      return this.listOfDocuments[item.name] && this.listOfDocuments[item.name].length > 0
    },
    getCols (item) { // for component 19
      const flagObj = item.default_value.checkboxFlags
      return (flagObj.signature || flagObj.date) ? (flagObj.signature && flagObj.date) ? 8 : 10 : 12
    },
    getCheckboxLabelWidth (options) {
      options.label = options.label || ''
      return (options.label.length + 100) > 200 ? 350 : (options.label.length + 100)
    },
    getActiveOptions (defaulValue) {
      if (!defaulValue.options || defaulValue.options.length < 1) return []
      let resultOptions = defaulValue.options.filter(x => x.isactive || x.isactive === undefined)
      resultOptions = resultOptions && resultOptions.length > 0 ? resultOptions : []
      return resultOptions
    },
    eventHandler (evt, index, logic) {
      const element = this.$formatter.cloneVariable(this.aliasFieldsList[index])
      // eslint-disable-next-line
      let logicalFunction = new Function('instance', 'input', 'data', element[`logic_${logic.logicname}`])
      logicalFunction(this, evt.target.value, this.fields)
    },
    // Extension get method
    getProperty (key) {
      return this.fields[key]
    },
    // Extension set method
    setProperty (key, value) {
      this.fields[key] = value
    },
    showAlert (type, message) {
      this.$root.$emit('snackbar', { snackbar: true, color: type, text: message })
    },
    getList (field) {
      const result = this.aliasFieldsList.find(x => x.name === field)
      if (result) {
        const list = result.customOptions ? result.customOptions : []
        return list
      } else return []
    },
    // Extension set select options method
    setOptionList (key, value, itemValue) {
      const foundElementIndex = this.aliasFieldsList.findIndex(x => x.name === key)
      if (foundElementIndex !== -1) {
        const item = this.$formatter.cloneVariable(this.aliasFieldsList[foundElementIndex])
        item.customOptions = value
        item.optionName = itemValue
        item.optionValue = itemValue
        this.$set(this.aliasFieldsList, foundElementIndex, item)
      }
    },
    // Manuppulation for Validation on change
    checkForValidationOrFunction (element, value, index) {
      element.conditionscript = () => {}
      // Conditional hide
      if (element.hideField) {
        const foundElementIndex = this.aliasFieldsList.findIndex(x => x.name === element.hideField)
        // eslint-disable-next-line
        let checkCondition = element.type === 3 ? element.hideConditions.value == value.toString() : element.hideConditions.value === value // Condition for checkbox
        if (checkCondition) {
          this.aliasFieldsList[foundElementIndex].isShow = element.hideConditions.condition
        } else this.aliasFieldsList[foundElementIndex].isShow = !element.hideConditions.condition
        // this.$forceUpdate()
      }
      // Onchange select logic script
      if (element.type === 5 && element.logicscripts && element.logicscripts.length > 0) {
        if (element.logicscripts.length > 0) {
          const haschangeEvent = element.logicscripts.find(x => x.logicevent === 'change' || x.logicevent === 'onchange')
          if (haschangeEvent) {
            // eslint-disable-next-line
            let logicalFunction = new Function('instance', 'input', 'data', element[`logic_${haschangeEvent.logicname}`])
            logicalFunction(this, value, this.fields)
          }
        }
      }
    },
    surveyCheckAndSetHideFields (element, answer, index) {
      element.fieldsToHide = this.getHideFieldList(element, answer)
      /* watch for field value change */
      this.$watch(`fields.${element.name}.answer`, function (val) {
        element.fieldsToHide = this.getHideFieldList(element, val)
        this.$set(this.aliasFieldsList, index, { ...element })
      }, { deep: true })
    },
    getHideFieldList (element, answer) {
      const fieldsToHide = []
      const surveyConditionScripts = element.default_value.surveyConditionScripts || []
      surveyConditionScripts.forEach(condition => {
        if (condition.visibility) {
          if (condition.answer_value !== answer) fieldsToHide.push(condition.field)
        } else {
          if (condition.answer_value === answer) fieldsToHide.push(condition.field)
        }
      })
      return fieldsToHide
    },
    getUserName () {
      // eslint-disable-next-line
      const { first_name, last_name } = this.userDetails
      // eslint-disable-next-line
      return `${first_name || ''} ${last_name || ''}`
    },
    getUserInitial () {
      return this.userDetails.initialer || ''
    },
    getUsers () {
      return this.tenantUsers
    },
    hasConditionScript (script) {
      // if (needDecode) script = this.$formatter.$_decodeStringTobase64(script)
      const substrings = ['show =', 'show=']
      return substrings.some(v => script.indexOf(v) !== -1)
    },
    // Extension get projects method
    debouncedGetProjects (searchTerm) {
      return new Promise((resolve, reject) => {
        hostAppApi.get(`${this.getHostRefApi}get_active_projects?user_id=${this.userId}&search=${searchTerm}`)
          .then((response) => {
            resolve(response.data)
          }).catch(e => reject(e))
      })
    },
    handleFileUpload (event, propId, propName) {
      this.uploadDocumentHandler(propId, propName, event.target.files)
    },
    handleRadioButtonClick (item, options, value) {
      const radioKey = item.name
      if (Object.prototype.hasOwnProperty.call(this.radioLastCheckHistory, radioKey) && this.radioLastCheckHistory[radioKey] === value) {
        this.fields[item.name] = ''
        delete this.radioLastCheckHistory[radioKey]
      } else {
        this.radioLastCheckHistory[item.name] = value
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('callRerender')
  }
}
</script>
<style>
.attachment-icon-delete {
  float: right !important;
}
.signature--pad {
  border: 1px solid grey;
}
.position_fixed {
  position: sticky;
  bottom: 10px !important;
}
</style>
